module.exports = {
    lockerId: "37",
    password: "123456",
    apiBase: "https://erusmu9p86.execute-api.eu-central-1.amazonaws.com/dev",
    drawerStatus: {
        AVAILABLE: "0",
        AWAITING_PACKAGE: "1",
        AWAITING_RECIPIENT: "2",
        UNUSABLE_OR_FAULTY: "3"
    },
    drawerStatusText: {
        "0": "Disabled",
        "1": "Active",
    },
    drawerSizeText: {
        "s": "Small",
        "m": "Medium",
        "l": "Large",
        "xl": "Extra Large"
    },
    versionNumber: "02.01.2022",
};
