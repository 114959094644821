<template>
  <header>
    <section class="logo">
      <router-link to="/">
        <img src="@/assets/mls-logo.png" />
      </router-link>
      <router-link to="/">
        <img src="@/assets/onniversum-logo.png" />
      </router-link>
    </section>
    <section class="ret-home-btn">
      <a href="#" @click="$router.go(-1)">
        <span>Back</span>
      </a>
      <router-link to="/">
        <span>Home</span>
      </router-link>
    </section>
    <section class="time">
      <span @click="handleCounter">{{ dateTime }}</span>
    </section>
  </header>
  <main>
    <router-view />
  </main>
</template>

<style lang="scss">
@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

* {
  box-sizing: border-box;
  user-select: none;
}

body {
  font-family: "Open Sans", "Arial", sans-serif;
}

header {
  background-color: #fff;
  box-shadow: 0 5px 10px #ebebeb;
  width: 100%;
  padding: 15px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .logo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
    align-content: center;
    img {
      display: block;
      width: 200px;
    }
  }

  .ret-home-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    a {
      margin: 0 15px;
      color: #fff;
      text-decoration: none;
      background-color: #f07e26;
      display: block;
      padding: 15px 25px;
      border-radius: 5px;
      font-size: 24px;
    }
  }

  .time {
    font-size: 18px;
    font-family: "Courier New";
    font-weight: bold;
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 50px;
}
</style>

<script>
export default {
  name: "Main",
  data() {
    return {
      dateTime: "",
      counter: 0,
      lastCountUp: 0,
    };
  },

  methods: {
    updateClock: function() {
      const dateTime = new Date().toLocaleString("tr-TR");
      this.dateTime = dateTime.substring(0, dateTime.length - 3);
    },
    handleCounter: function() {
      if (Date.now() - this.lastCountUp >= 1000) this.counter = 0;

      this.counter++;
      this.lastCountUp = Date.now();

      if (this.counter >= 10) {
        this.$router.push("/configuration");
      }
    },
  },

  mounted() {
    this.counter = 0;

    // Update clock every x seconds.
    this.updateClock();
    setInterval(this.updateClock, 5000);
  },
};
</script>
