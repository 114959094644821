import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { login } from './auth/lockerApi'
import lockerConfig from './lockerConfig'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

(async () => {
    createApp(App).use(VueSweetalert2).use(router).mount('#app');
})();
