import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

import { login } from '../auth/lockerApi'
import lockerConfig from '../lockerConfig'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home2',
    name: 'Home2',
    component: () => import('../views/Home2.vue')
  },
  {
    path: '/leave',
    name: 'Leave',
    component: () => import('../views/Leave.vue')
  },
  {
    path: '/leave/selectSize',
    name: 'Leave/SelectSize',
    component: () => import('../components/Leave/SelectSize.vue')
  },
  {
    path: '/leave/receiptEmail',
    name: 'Leave/ReceiptEmail',
    component: () => import('../components/Leave/ReceiptEmail.vue')
  },
  {
    path: '/leave/submit',
    name: 'Leave/Submit',
    component: () => import('../components/Leave/Submit.vue')
  },
  {
    path: '/receive',
    name: 'Receive',
    component: () => import('../views/Receive.vue')
  },
  {
    path: '/receive/submit',
    name: 'Receive/Submit',
    component: () => import('../components/Receive/Submit.vue')
  },
  {
    path: '/configuration',
    name: 'Configuration',
    component: () => import('../views/Configuration.vue')
  },
  {
    path: '/noConnection',
    name: 'NoConnection',
    component: () => import('../views/NoConnection.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async function (to, from, next) {
  // TODO: find a better way to handle. This consumes so much resource.
  let authRoutes = [
    'Home',
    'Leave',
    'Receive',
    'Configuration'
  ];

  if (authRoutes.includes(to.name)) {
    try {
      await login(lockerConfig.lockerId, lockerConfig.password);
    } catch (err) {
      console.log('Login Error', err);
      return next({
        name: 'NoConnection'
      });
    }
  }

  next();
});

export default router
